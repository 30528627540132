import React, { useCallback } from 'react';
import { Card } from 'react-bootstrap';
// import slide1 from '../../assets/images/tickets/slide1.png';
import {
  CalenderIcon2,
  InPersonIcon2,
  LocationIcon,
  // TicketIcon,
  // TourIcon,
  TourIconNew,
  VirtualIcon2,
  // VirtualLiveIcon,
} from '../util/CustomIcons';
import moment from 'moment';
import {
  DATE,
  DATEFORMAT,
  checkEventStartOrEnd,
  // checkFileType,
  getLowestPrice,
  getSalesStartDate,
  showStatusComingSoonEnded,
  truncate,
} from '../../utils/util';
import { Link, useNavigate } from 'react-router-dom';
import Tooltip from '../common/Tooltip';
import CurrencyFormat from 'react-currency-format';
import { MdOutlineStadium } from 'react-icons/md';
import {
  btnPrimaryClass,
  // primaryBtnClassGradient,
} from '../common/DefaultClasses';
import { BiBookmark, BiSolidBookmark } from 'react-icons/bi';
import { useEventFavSave } from '../../hooks/event/useEventList';
import { RiHeartFill, RiHeartLine } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import useDecodedToken from '../../hooks/useDecodedToken';
import { useMemo } from 'react';
import { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper';
// import Video from '../common/Video';
// import Image from '../common/Image';
import EventImageVideo from '../common/EventImageVideo';
import { Skeleton } from '../util/Skeleton';
import {
  getTicketStatus,
  setStatusForInPerson,
  setStatusVirtual,
} from '../../pages/landing/v2/hooks/useEventDetailPageHooks';
import { eventStatusMessage } from '../../utils/LoaderMessage';

export default function EventCardV2({ data, preview, from = '' }) {
  // let navigate = useNavigate();
  let decodedToken = useDecodedToken();
  const navigate = useNavigate();
  const message = useRef(null);
  const profileImage =
    data?.profile_image && data?.profile_image?.length > 0
      ? data?.profile_image
      : [data?.profile_image];

  const status = useMemo(
    () =>
      getTicketStatus({ data: data?.category }, data?.event_type, data?.venue),
    [data],
  );

  const getEventTicketStatus = useMemo(() => {
    // console.log('data?.event_type', status);
    if (data?.event_type === 'Virtual') {
      if (setStatusVirtual(status)?.result) {
        message.current = (
          <span className="text-rose-500">
            {setStatusVirtual(status)?.message ===
            eventStatusMessage?.virtualTicketSold
              ? 'Sold Out'
              : 'Sale Ended'}
          </span>
        );
        return true;
      }
    } else if (data?.event_type === 'Physical') {
      if (setStatusForInPerson(status)?.result) {
        message.current = (
          <span className="text-rose-500">
            {setStatusForInPerson(status)?.message ===
            eventStatusMessage?.inPersonTicketSold
              ? 'Sold Out'
              : 'Sale Ended'}
          </span>
        );
        return true;
      }
    } else if (data?.event_type === 'both') {
      if (
        setStatusForInPerson(status)?.result &&
        setStatusVirtual(status)?.result
      ) {
        message.current = (
          <span className="text-rose-500">
            {setStatusForInPerson(status)?.message ===
            eventStatusMessage?.inPersonTicketSold
              ? 'Sold Out'
              : 'Sale Ended'}
          </span>
        );
        return true;
      }
    }
    return false;
  }, [status, data?.event_type]);

  // console.log('getEventTicketStatus', getEventTicketStatus);

  // const onClick = useCallback(
  //   (type) => {
  //     return preview
  //       ? type === 'buyNow'
  //         ? ''
  //         : navigate(data?.previewLink)
  //       : navigate(`/event-detail/${data?.slug}`);
  //   },
  //   [data?.previewLink, data?.slug, preview, navigate],
  // );

  const { makeItFavourite, saveEvent } = useEventFavSave(data?._id);

  const userDetails = useSelector((state) => state.userDetails);

  const checkIsEventStarted = useCallback(
    (data) => {
      let started =
        moment
          .duration(moment(data?.start_date_time).diff(moment()))
          .asSeconds() <= 0;

      let ended =
        moment
          .duration(moment().diff(moment(data?.end_date_time)))
          .asSeconds() >= 0;

      if (started && !ended) {
        return { status: false, data: !preview && checkEventStartOrEnd(data) };
      } else {
        return {
          status: true,
          data: moment(
            moment.utc(data?.start_date_time).local(),
            'YYYY-MM-DD hh:mm a',
          ).format(DATEFORMAT),
        };
      }
    },
    [preview],
  );

  // const showPill = useCallback(
  //   (data) => {
  //     let started =
  //       moment
  //         .duration(moment(data?.start_date_time).diff(moment()))
  //         .asSeconds() <= 0;

  //     let ended =
  //       moment
  //         .duration(moment().diff(moment(data?.end_date_time)))
  //         .asSeconds() >= 0;

  //     if (started && !ended) {
  //       return;
  //     } else {
  //       return !preview && checkEventStartOrEnd(data);
  //     }
  //   },
  //   [preview],
  // );

  // const navigationPrevRef = React.useRef(null);
  // const navigationNextRef = React.useRef(null);
  return (
    <React.Fragment>
      <div className="group">
        <div className="group-hover:-translate-y-2 group-hover:scale-[1.005] duration-300 ease-in-out">
          <Card className="rounded-[12px] overflow-hidden w-full h-full relative bg-color-base-1 border-1 border-level-4 shadow-md shadow-level-4 group-hover:duration-300 ease-in group-hover:shadow-level-4">
            <Card.Body className="p-0 h-full">
              <div className="relative">
                {profileImage ? (
                  <Swiper
                    hashNavigation={{
                      watchState: true,
                    }}
                    // loopedSlides={6}
                    loop={profileImage?.length > 1 ? true : false}
                    centeredSlides={true}
                    // freeMode={true}
                    allowTouchMove={true}
                    pagination={{
                      dynamicBullets: true,
                      clickable: true,
                    }}
                    // allowSlideNext={true}
                    // allowSlidePrev={true}
                    // lazy={true}
                    slidesPerView={1}
                    // freeMode={true}
                    // effect="fade"
                    spaceBetween={10}
                    //   touchStartPreventDefault={false}
                    // pagination={{ clickable: true }}
                    // effect="fade"
                    grabCursor={true}
                    // sticky={true}

                    // onSwiper={(s) => {
                    //   setSwiper(s);
                    // }}
                    // onBeforeInit={(swiper) => {
                    //   navigationPrevRef.current =
                    //     swiper.params.navigation.prevEl;
                    //   navigationNextRef.current =
                    //     swiper.params.navigation.nextEl;
                    // }}
                    autoplay={
                      profileImage?.length > 1
                        ? {
                            delay: 5000,
                            disableOnInteraction: false,
                          }
                        : false
                    }
                    modules={[Autoplay, Pagination, Navigation]}
                    className="card_swiper h-full w-full"
                    // className="items-center"
                  >
                    {profileImage ? (
                      <>
                        {profileImage?.map((image, index) => (
                          <SwiperSlide
                            className="relative h-full w-full"
                            key={index}
                          >
                            <div className="relative w-full h-full">
                              <Link
                                to={
                                  preview
                                    ? data?.previewLink
                                    : `/event-detail/${data?.slug}`
                                }
                                target={preview ? '_blank' : ''}
                                className="flex w-full h-full max-h-[18em] overflow-hidden"
                              >
                                <div className="w-full h-0 pb-[52%]"></div>
                                <div className="absolute inset-0 w-full h-full overflow-hidden rounded-md rounded-b-none object-cover">
                                  <EventImageVideo
                                    data={image}
                                    alt={'Profile'}
                                    className={'w-full h-full object-cover'}
                                    loading=""
                                    // width="384px"
                                    // height="512px"
                                  />
                                  {/* {checkFileType(image) === 'video' ? (
                              <Video
                                className="w-full h-full object-cover"
                                src={image?.url}
                                removeControls={true}
                              />
                            ) : (
                              <Image
                                src={image?.url}
                                alt="Profile"
                                className="w-full h-full object-cover"
                              />
                            )} */}
                                  {/* <img
                              loading="lazy"
                              src={image?.url}
                              alt="event-img"
                              className="w-full h-full object-cover childCardHover"
                            /> */}
                                </div>
                              </Link>

                              {/* {!preview && (
                        <div className="shrink-0 absolute top-2 right-2">
                          {data?.tour_id !== '' && (
                            <Tooltip
                              placement={'top'}
                              title={data?.tourData?.name}
                            >
                              <Link
                                to={`/tour/${
                                  data?.tourData?.slug || data?.tour_id
                                }`}
                                className="w-7 h-7 text-lg rounded-full bg-neutral-900 backdrop-blur-2xl bg-opacity-70 relative"
                              >
                                <TourIconNew className="text-whiteNew-0 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-2xl p-[2px]" />
                              </Link>
                            </Tooltip>
                          )}
                        </div>
                      )} */}
                              {/* {!preview && (
              <div className="absolute right-1 top-1">
                <Tooltip
                  placement={'bottomRight'}
                  title={
                    data?.event_type === 'both'
                      ? 'In-Person & Virtual Event'
                      : data?.event_type === 'Virtual'
                      ? 'Virtual'
                      : 'In-Person'
                  }
                  // getPopupContainer={(triggerNode) => triggerNode.parentElement}
                >
                  {data?.event_type === 'both' ? (
                    <>
                      <span className="flex gap-2 text-lg rounded-full p-1 px-2 bg-neutral-600 backdrop-blur-2xl bg-opacity-40">
                        <span className="text-2xl rounded-full relative">
                          <VirtualIcon className="text-white" />
                        </span>
                        <span className="text-2xl rounded-full relative">
                          <PhysicalIcon className="text-white" />
                        </span>
                      </span>
                    </>
                  ) : data?.event_type === 'Virtual' ? (
                    <span className="w-8 h-8 text-lg rounded-full bg-neutral-600 backdrop-blur-2xl bg-opacity-40 relative">
                      <VirtualIcon className="text-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
                    </span>
                  ) : (
                    <span className="w-8 h-8 text-lg rounded-full bg-neutral-600 backdrop-blur-2xl bg-opacity-40 relative">
                      <PhysicalIcon className="text-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
                    </span>
                  )}
                </Tooltip>
              </div>
            )} */}
                            </div>
                          </SwiperSlide>
                        ))}
                      </>
                    ) : (
                      <>
                        {[...Array(2)].map((_, idx) => (
                          <SwiperSlide
                            className="relative pointer-events-none"
                            key={idx}
                          >
                            <Skeleton />
                          </SwiperSlide>
                        ))}
                      </>
                    )}
                  </Swiper>
                ) : null}
              </div>
              {!preview && (
                <div className="shrink-0 absolute top-2 right-2 z-[1]">
                  {data?.tour_id !== '' && (
                    <Tooltip placement={'top'} title={data?.tourData?.name}>
                      <Link
                        to={`/tour/${data?.tourData?.slug || data?.tour_id}`}
                        className="w-7 h-7 text-lg rounded-full bg-neutral-900 backdrop-blur-2xl bg-opacity-70 relative"
                      >
                        <TourIconNew className="text-whiteNew-0 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-2xl p-[2px]" />
                      </Link>
                    </Tooltip>
                  )}
                </div>
              )}
              <div className="dark:bg-color-base-3 bg-color-base-1 space-y-3.5 relative max-h-[18rem] flex flex-col justify-between">
                <div>
                  {/* bg-[#231c1c] */}
                  <div className="relative dark:bg-color-base-1 bg-zinc-100 flex justify-between items-center after:absolute after:content-[''] dark:after:bg-level-4 after:bg-neutral-200 after:bottom-0 after:left-0 after:h-1 after:w-full after:flex after:z-10 py-2.5 px-3 min-h-[53px]">
                    <div className="flex gap-2 justify-between w-full">
                      <div className="w-full min-h-[30px]">
                        <h6 className="dark:hover:text-yellow-400 duration-150 ease-in cursor-pointer text-base 2xl:text-lg text-zinc-900 dark:text-whiteNew-0 leading-tight">
                          {preview ? (
                            <a
                              href={data?.previewLink}
                              target={preview ? '_blank' : ''}
                              rel="noreferrer"
                            >
                              <Tooltip
                                placement={'top'}
                                title={data?.name}
                                childClassname={'inline-block w-full mt-1.5'}
                              >
                                <span className="inline-block w-full truncate">
                                  {/* {truncate(data?.name, 25) || '-'} */}
                                  {data?.name || '-'}
                                </span>
                              </Tooltip>
                            </a>
                          ) : (
                            <Link to={`/event-detail/${data?.slug}`}>
                              <Tooltip
                                placement={'top'}
                                title={data?.name}
                                childClassname={'inline-block w-full mt-1.5'}
                              >
                                <span className="inline-block w-full truncate">
                                  {/* {truncate(data?.name, 25) || '-'} */}
                                  {data?.name || '-'}
                                </span>
                              </Tooltip>
                            </Link>
                          )}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`px-3 pt-2 space-y-1.5 ${
                      preview ? '' : 'min-h-[147px]'
                    }`}
                  >
                    <div className="flex justify-between items-start">
                      <div className="space-y-1.5">
                        {!preview && (
                          <div className="flex gap-2 items-center">
                            {checkIsEventStarted(data)?.status && (
                              <div>
                                <span className="text-sm text-zinc-900 dark:text-white">
                                  <CalenderIcon2 />
                                </span>
                              </div>
                            )}

                            <div>
                              <h6 className="text-sm text-zinc-900 dark:text-white font-gray-50 leading-tight">
                                {checkIsEventStarted(data)?.data || '-'}
                              </h6>
                            </div>
                          </div>
                        )}
                        {!preview && (
                          <div className="flex gap-2 items-start">
                            <div className="pt-[1px]">
                              <span className="text-sm text-zinc-900 dark:text-whiteNew-0">
                                <MdOutlineStadium />
                              </span>
                            </div>
                            <div className="truncate w-full flex ">
                              <p className="text-sm text-zinc-900 dark:text-whiteNew-0 font-gray-50 font-light flex leading-tight">
                                {/* <Tooltip
                        title={
                          data?.venue && data?.event_type !== 'Virtual' ? (
                            <span className="flex flex-col">
                              <span>
                                {data?.venue[0]?.name?.split('-')
                                  ? truncate(
                                      data?.venue[0]?.name?.split('-')?.[0],
                                      20,
                                    )
                                  : truncate(data?.venue[0]?.name, 20)}
                              </span>
                            </span>
                          ) : data?.event_type === 'both' ? (
                            'In-Person & Virtual Event'
                          ) : data?.event_type === 'Virtual' ? (
                            'Virtual'
                          ) : (
                            'N/A'
                          )
                        }
                        placement={'top'}
                      > */}
                                {data?.venue &&
                                data?.event_type !== 'Virtual' ? (
                                  <span className="flex flex-col">
                                    <span>
                                      {data?.venue[0]?.name?.split('-')
                                        ? truncate(
                                            data?.venue[0]?.name?.split(
                                              '-',
                                            )?.[0],
                                            20,
                                          )
                                        : truncate(data?.venue[0]?.name, 20)}
                                    </span>
                                  </span>
                                ) : data?.event_type === 'both' ? (
                                  'In-Person & Virtual Event'
                                ) : data?.event_type === 'Virtual' ? (
                                  'Virtual'
                                ) : (
                                  'N/A'
                                )}
                                {/* </Tooltip> */}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                      <div>
                        {!preview && decodedToken && (
                          <div className="shrink-0">
                            <div className="flex items-center text-zinc-900 dark:text-whiteNew-0 gap-1.5 px-1 py-[4px] rounded-[5px]">
                              <div className="flex items-center gap-1 leading-tight">
                                <button onClick={makeItFavourite}>
                                  {userDetails?.data?.data?.favourite_event?.some(
                                    (e) => e === data?._id,
                                  ) ? (
                                    <>
                                      <Tooltip title={'Remove from Favourite'}>
                                        <RiHeartFill
                                          className={'text-xl text-red-600'}
                                        />
                                      </Tooltip>
                                    </>
                                  ) : (
                                    <>
                                      <Tooltip title={'Add to Favourite'}>
                                        <RiHeartLine
                                          className={'text-xl text-red-600'}
                                        />
                                      </Tooltip>
                                    </>
                                  )}
                                  {/* <HeartIcon className={'text-xl text-red-600'} /> */}
                                </button>
                              </div>
                              <div className="flex items-center">
                                <span className="flex h-[16px] w-[1px] bg-level-2"></span>
                              </div>
                              <div className="flex items-center gap-1 leading-tight">
                                <button onClick={saveEvent}>
                                  {userDetails?.data?.data?.saved_event?.some(
                                    (e) => e === data?._id,
                                  ) ? (
                                    <>
                                      <Tooltip title={'Remove from Saved '}>
                                        <BiSolidBookmark
                                          className={'text-lg text-amber-400'}
                                        />
                                      </Tooltip>
                                    </>
                                  ) : (
                                    <>
                                      <Tooltip title={'Add to Saved'}>
                                        <BiBookmark
                                          className={'text-lg text-amber-400'}
                                        />
                                      </Tooltip>
                                    </>
                                  )}
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="flex gap-2 items-start">
                      <div className="pt-[1px]">
                        <span className="text-sm text-zinc-900 dark:text-whiteNew-0">
                          <LocationIcon />
                        </span>
                      </div>
                      <div className="truncate w-full flex">
                        <span className="text-sm text-zinc-900 dark:text-whiteNew-0 font-gray-50 font-light flex leading-tight">
                          <Tooltip
                            title={
                              data?.venue && data?.event_type !== 'Virtual'
                                ? data?.venue[0]?.address && (
                                    <span className="flex flex-col">
                                      {data?.venue[0]?.address}
                                    </span>
                                  )
                                : data?.event_type === 'both'
                                ? 'In-Person & Virtual Event'
                                : data?.event_type === 'Virtual'
                                ? 'Virtual'
                                : 'N/A'
                            }
                            placement={'top'}
                          >
                            {data?.venue && data?.event_type !== 'Virtual' ? (
                              <span className="flex flex-col">
                                <span>
                                  {data?.venue[0]?.location
                                    ?.split(',')[2]
                                    ?.trim() === 'US' ||
                                  data?.venue[0]?.location
                                    ?.split(',')[2]
                                    ?.trim() === 'USA'
                                    ? `${
                                        data?.venue[0]?.location?.split(',')[0]
                                      }, ${
                                        data?.venue[0]?.location?.split(',')[1]
                                      }`
                                    : data?.venue[0]?.location || 'N/A'}
                                </span>
                              </span>
                            ) : data?.event_type === 'both' ? (
                              'In-Person & Virtual Event'
                            ) : data?.event_type === 'Virtual' ? (
                              'Virtual'
                            ) : (
                              'N/A'
                            )}
                          </Tooltip>
                        </span>
                      </div>
                    </div>
                    <div className="flex gap-2 items-center justify-between">
                      {!preview && (
                        <div className="flex shrink-0">
                          <Tooltip
                            placement={'topRight'}
                            title={
                              data?.event_type === 'both'
                                ? 'In-Person & Virtual Event'
                                : data?.event_type === 'Virtual'
                                ? 'Virtual'
                                : 'In-Person'
                            }
                            // getPopupContainer={(triggerNode) => triggerNode.parentElement}
                          >
                            {data?.event_type === 'both' ? (
                              <>
                                <div className="flex items-center text-zinc-900 dark:text-whiteNew-0 gap-1 bg-lightTheme-80 dark:bg-brown-700 px-1 rounded-[5px] min-h-[20px]">
                                  <div className="flex items-center gap-1 leading-none">
                                    <span>
                                      <InPersonIcon2
                                        className={
                                          'text-lg 2xl:text-xl text-zinc-900 dark:text-zinc-900'
                                        }
                                      />
                                    </span>
                                    <span className="flex text-[9px] 2xl:text-[9px] font-normal">
                                      In Person
                                    </span>
                                  </div>
                                  <div className="flex items-center">
                                    <span className="flex h-[12px] w-[1px] bg-whiteNew-0"></span>
                                  </div>
                                  <div className="flex items-center gap-1">
                                    <span>
                                      <VirtualIcon2
                                        className={
                                          'text-[10px] 2xl:text-xs text-zinc-900 dark:text-zinc-900'
                                        }
                                      />
                                    </span>
                                    <span className="flex text-[9px] 2xl:text-[9px] font-normal">
                                      Virtual
                                    </span>
                                  </div>
                                </div>
                              </>
                            ) : data?.event_type === 'Virtual' ? (
                              <div className="flex items-center text-zinc-900 dark:text-whiteNew-0 gap-1.5 bg-lightTheme-70 dark:bg-brown-700 px-1 rounded-[5px]">
                                <div className="flex items-center gap-1 leading-none min-h-[20px]">
                                  <span>
                                    <VirtualIcon2
                                      className={
                                        'text-xs text-zinc-900 dark:text-zinc-900'
                                      }
                                    />
                                  </span>
                                  <span className="flex text-[9px] 2xl:text-[9px] font-normal">
                                    Virtual
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <div className="flex items-center text-zinc-900 dark:text-whiteNew-0 gap-1.5 bg-lightTheme-70 dark:bg-brown-700 px-1 rounded-[5px]">
                                <div className="flex items-center gap-1 min-h-[20px]">
                                  <span>
                                    <InPersonIcon2 className={'text-xl'} />
                                  </span>
                                  <span className="flex text-[9px] 2xl:text-[10px] font-normal">
                                    In Person
                                  </span>
                                </div>
                              </div>
                            )}
                          </Tooltip>
                        </div>
                      )}
                      <div className="text-[10px] textSmall truncate">
                        {!preview && showStatusComingSoonEnded(data)}
                      </div>
                    </div>
                    <div className="mt-1.5 min-h-[27px]">
                      {from !== 'recent' &&
                      Date.parse(
                        moment(
                          moment.utc(data?.end_date_time).local(),
                          'YYYY-MM-DD hh:mm a',
                        ),
                      ) > Date.parse(moment()) &&
                      getSalesStartDate(data?.category) &&
                      getLowestPrice(data?.category) ? (
                        <div
                          className={`bg-component-gray-1 dark:bg-neutral-700 bg-opacity-100 dark:bg-opacity-40 py-1.5 px-1.5 text-zinc-900 dark:text-gray-50 rounded-[5px] duration-200 ease-in w-full flex justify-between items-center text-center leading-tight`}
                        >
                          {getLowestPrice(data?.category) && (
                            <span className="flex gap-2">
                              <span className="text-xs font-medium">
                                <CurrencyFormat
                                  value={
                                    isNaN(
                                      Number(
                                        getLowestPrice(data?.category),
                                      )?.toFixed(2),
                                    )
                                      ? '-'
                                      : '$' +
                                        Number(
                                          getLowestPrice(data?.category),
                                        )?.toFixed(2)
                                  }
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  decimalScale={2}
                                  prefix={'$'}
                                />
                                {''}+
                              </span>
                            </span>
                          )}

                          <span className="font-medium relative flex gap-2 items-center text-xs lg:text-[11px] xl:text-[11px]">
                            {getEventTicketStatus
                              ? message.current
                              : `Sales On: 
                          ${
                            moment(
                              new Date(
                                moment
                                  .utc(getSalesStartDate(data?.category))
                                  .local(),
                              ),
                              'YYYY-MM-DD',
                            ).format(DATE) || '-'
                          }`}
                          </span>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="pb-3.5 px-3.5">
                  {preview ? (
                    <a
                      href={data?.previewLink}
                      target="_blank"
                      without
                      rel="noreferrer"
                      // className={`${primaryBtnClassGradient}  text-gray-50 rounded-[0px] duration-200 ease-in w-full flex justify-center items-center`}
                      // className={`p-1.5 bg-gradient-to-r from-violet-400 to-violet-600 text-whiteNew-0 rounded-[6px] duration-300 ease-in flex justify-center items-center disabled:opacity-70  w-full`}
                      className={`${btnPrimaryClass} w-full`}
                    >
                      PREVIEW
                    </a>
                  ) : from !== 'recent' &&
                    Date.parse(
                      moment(
                        moment.utc(data?.end_date_time).local(),
                        'YYYY-MM-DD hh:mm a',
                      ),
                    ) > Date.parse(moment()) ? (
                    <button
                      // to={`/event-detail/${data?.slug}`}
                      // onClick={() => onClick('buyNow')}
                      // className={`${primaryBtnClassGradient} bg-landingPurple-60 text-gray-50 rounded-[0px] hover:bg-landingPurple-70 duration-200 ease-in w-full flex justify-center items-center`}
                      // className={`${primaryBtnClassGradient}  text-gray-50 rounded-[0px] duration-200 ease-in w-full flex justify-center items-center`}
                      // className={`p-1.5 bg-gradient-to-r from-[#454c9a] to-violet-600 text-whiteNew-0 rounded-[6px] duration-300 ease-in flex justify-center items-center disabled:opacity-70  w-full`}
                      className={`${btnPrimaryClass} w-full`}
                      onClick={() => navigate(`/event-detail/${data?.slug}`)}
                    >
                      <span className="font-medium relative flex gap-2 items-center">
                        {getEventTicketStatus ? 'VIEW EVENT' : 'Buy Now'}
                      </span>
                    </button>
                  ) : (
                    <button
                      // to={`/event-detail/${data?.slug}`}
                      // onClick={onClick}
                      // className={`${primaryBtnClassGradient} bg-landingPurple-60 text-gray-50 rounded-[0px] hover:bg-landingPurple-70 duration-200 ease-in w-full flex justify-end items-center`}
                      className={`${btnPrimaryClass} w-full`}
                      onClick={() => navigate(`/event-detail/${data?.slug}`)}
                    >
                      VIEW EVENT
                    </button>
                  )}
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
}
