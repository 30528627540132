import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedEvent: { slug: 'nhl-ice-hockey' },
  page: 1,
  pageSize: 10,
  reportData: {},
  eventList: [{ name: 'All', _id: 'all' }],
  selectedEventForFilter: { _id: 'all', name: 'all' },
  sorting: 'desc',
  all_order_count: 0,
  isOpen: false,
  selectedOrder: {},
  drawerInfo: {},
};

const Analytics = {
  totalEvents: { allEvents: 0, draftEvents: 0 },
  totalSales: { data: [], count: 0 },
  selectedSalePeriod: '1y',
  artistTeams: { allArtist: 0, individual: [], team: [] },
  eventLinks: [],
};

const AnalyticsbyEvent = {
  selectedEvent: {},
};

const slice = createSlice({
  name: 'analyticsTab',
  initialState,
  reducers: {
    setSelectedEvent: (state, { payload }) => {
      state.selectedEvent = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setPageSize: (state, { payload }) => {
      state.pageSize = payload;
    },
    setReportData: (state, { payload }) => {
      state.reportData = payload;
    },
    setEventList: (state, { payload }) => {
      state.eventList = payload;
    },
    setSelectedEventForFilters: (state, { payload }) => {
      state.selectedEventForFilter = payload;
    },
    setSorting: (state, { payload }) => {
      state.sorting = payload;
    },
    setIsOpen: (state, { payload }) => {
      state.isOpen = payload;
    },
    setSelectedOrder: (state, { payload }) => {
      state.selectedOrder = payload;
    },
    setAllOrderCount: (state, { payload }) => {
      state.all_order_count = payload;
    },
    setDrawerInfo: (state, { payload }) => {
      state.drawerInfo = payload;
    },
  },
});

const analyticsSubTabSlice = createSlice({
  name: 'analytics',
  initialState: Analytics,
  reducers: {
    setTotalEvents: (state, { payload }) => {
      state.totalEvents = payload;
    },
    setTotalSales: (state, { payload }) => {
      state.totalSales = payload;
    },
    setSelectedSalePeriod: (state, { payload }) => {
      state.selectedSalePeriod = payload;
    },
    setArtistTeams: (state, { payload }) => {
      state.artistTeams = payload;
    },
    setEventLinks: (state, { payload }) => {
      state.eventLinks = payload;
    },
  },
});

const analyticsSubTabByEventsSlice = createSlice({
  name: 'analytics_by_events',
  initialState: AnalyticsbyEvent,
  reducers: {
    setSelectedAnalyticEvents: (state, { payload }) => {
      state.selectedEvent = payload;
    },
  },
});

let analyticsReducer = slice.reducer;
let analyticsSubTabReducer = analyticsSubTabSlice.reducer;
let analyticsByEventsReducer = analyticsSubTabByEventsSlice.reducer;

export const {
  setSelectedEvent,
  setPage,
  setPageSize,
  setReportData,
  setEventList,
  setSelectedEventForFilters,
  setSorting,
  setIsOpen,
  setSelectedOrder,
  setAllOrderCount,
  setDrawerInfo,
} = slice.actions;

export const {
  setTotalEvents,
  setTotalSales,
  setSelectedSalePeriod,
  setArtistTeams,
  setEventLinks,
} = analyticsSubTabSlice.actions;

export const { setSelectedAnalyticEvents } =
  analyticsSubTabByEventsSlice.actions;

export { analyticsReducer, analyticsSubTabReducer, analyticsByEventsReducer };
