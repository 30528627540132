import React, { useEffect, useState } from 'react';
import HelmetComponent from '../../utils/HelmateComponent';
import TourBannerSwiper from '../../components/tour_compo/TourBannerSwiper';
import { NormalSkeleton, Skeleton } from '../../components/util/Skeleton';
import { Link, useParams } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import Tooltip from '../../components/common/Tooltip';
import { useArtistDetails } from '../../hooks/artist/useArtistDetails';
import NoDataFoundV1 from '../../components/common/NoDataFoundV1';
import { useRef } from 'react';
import useDecodedToken from '../../hooks/useDecodedToken';
import { useArtistFollow } from '../../hooks/event/artist/artist';
import {
  CardIconCustom,
  CustomFacebookIcon,
  CustomInstagramIcon,
  CustomLinkedIcon,
  CustomTwitterIcon,
  ListIconCustom,
} from '../../components/util/CustomIcons';

import moment from 'moment';
import {
  DATEFORMAT,
  MyLink,
  filterExpired,
  getLowestPrice,
  truncate,
} from '../../utils/util';
import CurrencyFormat from 'react-currency-format';
import { primaryBtnClassGradientSec } from '../../components/common/DefaultClasses';
import TopOrganiserSlider from '../../components/landing_page_v2/TopOrganiserSlider';
import { useSelector } from 'react-redux';
import { useTopArtistHooks } from '../landing/home_page/hooks/useHomePageHooks';
import { useCallback } from 'react';
import EventBanner from '../../components/landing_page_v2/event_booking_detail/EventBanner';
import TrendingSlider from '../../components/landing_page_v2/TrendingSlider';
import { FaRegStickyNote } from 'react-icons/fa';
import BannerSliderDetails from '../../components/landing_page_v2/BannerSliderDetails';
import { MdKeyboardArrowRight } from 'react-icons/md';

export default function ArtistPageV2() {
  const { id } = useParams();
  const { data, isLoading, refetch } = useArtistDetails(id);
  const [truncateDis, setTruncateDis] = useState(false);
  let decodedToken = useDecodedToken();
  const artistData = React.useMemo(() => data?.data || {}, [data]);
  const eventData = React.useMemo(() => data?.eventData || [], [data]);
  let myRef = useRef();
  let page_limit = useSelector((state) => state?.homePageEvents);
  const { topartist } = useTopArtistHooks(page_limit?.page, page_limit?.limit);
  let PublishedEvents = useSelector((state) => state?.publishedEvents);
  const { artist } = PublishedEvents;
  const { FollowMutation } = useArtistFollow([], 'singleArtist', refetch);
  const [dataViews, setDataViews] = useState('list');

  React.useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [isLoading]);

  // React.useEffect(() => {
  //   // Clear data from Redux store when leaving the home page
  //   return () => {
  //     dispatch(resetEvents());
  //     dispatch(resetPageLimit());
  //   };
  // }, [dispatch]);

  // if (isLoading) {
  //   return <PageLoader2 darkTheme removeText />;
  // }

  const socialMediaLinks = useCallback(() => {
    if (
      !artistData?.socialmedia_urls?.facebook_url &&
      !artistData?.socialmedia_urls?.instagram_url &&
      !artistData?.socialmedia_urls?.twitter_url &&
      !artistData?.socialmedia_urls?.telegram_url
    ) {
      return null;
    }
    return (
      <>
        {artistData?.socialmedia_urls?.facebook_url && (
          <Tooltip title={artistData?.socialmedia_urls?.facebook_url}>
            <MyLink
              target={
                artistData?.socialmedia_urls?.facebook_url ? '_blank' : ''
              }
              rel="noopener noreferrer"
              className="flex"
              to={`${
                artistData?.socialmedia_urls?.facebook_url
                  ? artistData?.socialmedia_urls?.facebook_url
                  : '#'
              }`}
            >
              <button className="relative text-xl dark:text-zinc-300 text-zinc-800 hover:text-sky-600 dark:hover:text-whiteNew-0">
                <CustomFacebookIcon />
              </button>
            </MyLink>
          </Tooltip>
        )}
        {artistData?.socialmedia_urls?.instagram_url && (
          <Tooltip title={artistData?.socialmedia_urls?.instagram_url}>
            <MyLink
              target={
                artistData?.socialmedia_urls?.instagram_url ? '_blank' : ''
              }
              rel="noopener noreferrer"
              className="flex"
              to={`${
                artistData?.socialmedia_urls?.instagram_url
                  ? artistData?.socialmedia_urls?.instagram_url
                  : '#'
              }`}
            >
              {' '}
              <button className="relative text-xl dark:text-zinc-300 text-zinc-800 hover:text-sky-600 dark:hover:text-whiteNew-0">
                <CustomInstagramIcon />
              </button>
            </MyLink>
          </Tooltip>
        )}
        {artistData?.socialmedia_urls?.twitter_url && (
          <Tooltip title={artistData?.socialmedia_urls?.twitter_url}>
            <MyLink
              target={artistData?.socialmedia_urls?.twitter_url ? '_blank' : ''}
              rel="noopener noreferrer"
              className="flex"
              to={`${
                artistData?.socialmedia_urls?.twitter_url
                  ? artistData?.socialmedia_urls?.twitter_url
                  : '#'
              }`}
            >
              <button className="relative text-xl dark:text-zinc-300 text-zinc-800 hover:text-sky-600 dark:hover:text-whiteNew-0">
                <CustomTwitterIcon />
              </button>
            </MyLink>
          </Tooltip>
        )}
        {artistData?.socialmedia_urls?.telegram_url && (
          <Tooltip title={artistData?.socialmedia_urls?.telegram_url}>
            <MyLink
              target={artistData?.socialmedia_urls?.twitter_url ? '_blank' : ''}
              rel="noopener noreferrer"
              className="flex"
              to={`${
                artistData?.socialmedia_urls?.telegram_url
                  ? artistData?.socialmedia_urls?.telegram_url
                  : '#'
              }`}
            >
              <button className="relative text-xl dark:text-zinc-300 text-zinc-800 hover:text-sky-600 dark:hover:text-whiteNew-0">
                <CustomLinkedIcon />
              </button>
            </MyLink>
          </Tooltip>
        )}
      </>
    );
  }, [artistData]);

  const artistImage = useCallback((image) => {
    return image?.length > 0 ? image : [image];
  }, []);

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the width threshold as needed
    };

    checkIfMobile(); // Initial check

    // Add event listener to detect window resize
    window.addEventListener('resize', checkIfMobile);

    // Cleanup by removing event listener
    return () => {
      window.removeEventListener('resize', checkIfMobile);
    };
  }, []); // Empty dependency array ensures effect runs only once on mount

  return (
    <React.Fragment>
      <div className="srollToSelect" ref={myRef}></div>
      <div>
        <HelmetComponent title={'NFTGOTIX | Artist Details'} />
        <section className="bg-color-base-1">
          <div className="relative z-0">
            <div className="w-full">
              <div className="relative">
                <div className="relative h-full banner_height_auto">
                  <div
                    className="absolute inset-0 z-0"
                    style={{
                      background:
                        'linear-gradient(71deg, rgba(225,216,201,1) 6%, rgba(225,216,201,1) 24%, rgba(165,189,203,1) 80%, rgba(158,184,215,0.9724264705882353) 92%, rgba(114,143,161,1) 100%)',
                    }}
                  >
                    <div className="bg-color-base-2 absolute inset-0 bg-opacity-80"></div>
                  </div>

                  <EventBanner
                    isLoading={!artistData?.banner_image}
                    image={artistData?.banner_image}
                  >
                    <div className="container-fluid max-w-[1800px]">
                      <div className="z-10 h-full relative lg:px-4 px-[0px]">
                        <div className="flex dark:bg-zinc-950 bg-whiteNew-0 dark:bg-opacity-60 bg-opacity-70 backdrop-blur-sm rounded-2xl flex-col lg:flex-row gap-3 shrink-0 items-center lg:items-start mt-[0px] lg:top-[100px] relative border-[1px] dark:border-zinc-800 border-zinc-300 py-3.5 px-3.5">
                          <div className="lg:basis-2/12 basis-full justify-center items-center flex h-[200px] lg:h-auto w-[200px] lg:w-auto">
                            <div className="flex hz-full w-full bg-zinc-950 rounded-2xl border-0 dark:border-zinc-700 border-whiteNew-0">
                              {artistData?.profile_image ? (
                                <>
                                  <div className="relative w-full h-full">
                                    <div className="w-full h-0 pb-[100%]"></div>
                                    <div className="absolute inset-0 w-full h-full rounded-md rounded-b-none">
                                      <BannerSliderDetails
                                        image={artistImage(
                                          artistData?.profile_image,
                                        )}
                                      />
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="relative w-full h-full">
                                    <div className="w-full h-0 pb-[100%]"></div>
                                    <div className="absolute inset-0 w-full h-full overflow-hidden rounded-md rounded-b-none">
                                      <Skeleton
                                        className={
                                          'w-full h-full absolute inset-0'
                                        }
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="lg:basis-5/6 basis-full">
                            <div className="flex flex-col">
                              {artistData ? (
                                <>
                                  <div className="flex items-center justify-between pe-[0px] flex-col md:flex-row md:pe-0 gap-4">
                                    <div className="flex flex-col gap-2 justify-center lg:justify-start md:justify-start items-center lg:items-start md:items-start min-w-[200px]">
                                      <h1 className="text-2xl dark:text-whiteNew-0 text-zinc-800 after:block relative dark:after:bg-zinc-200 font-medium w-full">
                                        {!artistData?.name ? (
                                          <span className="inline-block relative w-full min-h-[35px] max-h-[35px] max-w-[200px] overflow-hidden rounded-md">
                                            <Skeleton
                                              className={
                                                'absolute inset-0 w-full h-full bg-opacity-25'
                                              }
                                            />
                                          </span>
                                        ) : (
                                          <>
                                            {artistData?.name?.split('@')
                                              ?.length > 1
                                              ? artistData?.name?.split('@')[1]
                                              : artistData?.name?.split('@')[0]}
                                          </>
                                        )}
                                      </h1>
                                      <span className="flex h-[2px] w-20 bg-zinc-800"></span>
                                    </div>
                                    <div className="shrink-0 w-full md:w-auto flex justify-center mt-3.5 md:mt-[0px]">
                                      {data?.data?.followers &&
                                      data?.data?.followers?.includes(
                                        decodedToken?.id,
                                      ) ? (
                                        <div
                                          className={`flex flex-row items-center py-1.5 rounded-md ps-1.5 pe-1.5 dark:bg-zinc-800 dark:border-zinc-700 border-zinc-200 border-[1px]  gap-3 ${
                                            !socialMediaLinks()
                                              ? 'bg-transparent border-0'
                                              : 'bg-whiteNew-0 shadow-lg min-w-[130px]'
                                          }`}
                                        >
                                          {decodedToken?.id && (
                                            <button
                                              className={
                                                'p-1.5 dark:bg-landingPurple-60 bg-sky-600 text-gray-50 dark:hover:bg-landingPurple-70 hover:sky-700 duration-200 ease-in rounded-md w-24 min-w-[110px]'
                                              }
                                              onClick={() => {
                                                FollowMutation.mutate({
                                                  method: 'unfollow',
                                                  artistID: artistData?._id,
                                                });
                                              }}
                                            >
                                              UNFOLLOW
                                            </button>
                                          )}
                                          {/* <span className="flex flex-row dark:bg-slate-50 bg-zinc-900 bg-opacity-25 h-6 w-[1px] mx-2.5"></span> */}
                                          <div
                                            className={`flex gap-3 ${
                                              !socialMediaLinks() ? '' : ''
                                            }`}
                                          >
                                            {socialMediaLinks()}
                                          </div>
                                        </div>
                                      ) : (
                                        <div
                                          className={`flex flex-row items-center  py-1.5 rounded-md ps-1.5 pe-1.5 dark:bg-zinc-800 dark:bg-opacity-70 dark:border-zinc-700 border-zinc-200  min-h-[42px] min-w-[130px] justify-center gap-3 ${
                                            !socialMediaLinks()
                                              ? 'bg-transparent border-0 shadow-none'
                                              : 'bg-whiteNew-0 border-[1px] shadow-lg'
                                          }`}
                                        >
                                          {decodedToken?.id && (
                                            <>
                                              <button
                                                className={
                                                  'p-1.5 dark:bg-landingPurple-60 bg-sky-600 text-gray-50 dark:hover:bg-landingPurple-70 hover:bg-sky-700 duration-200 ease-in rounded-md w-24 min-w-[110px]'
                                                }
                                                onClick={() => {
                                                  FollowMutation.mutate({
                                                    method: 'follow',
                                                    artistID: artistData?._id,
                                                  });
                                                }}
                                              >
                                                FOLLOW
                                              </button>
                                              {/* {artistData?.socialmedia_urls &&
                                                Object.keys(
                                                  artistData?.socialmedia_urls,
                                                )?.length > 0 && (
                                                  <span className="flex flex-row dark:bg-slate-50 bg-zinc-900 bg-opacity-25 h-6 w-[1px] mx-2.5"></span>
                                                )} */}
                                            </>
                                          )}
                                          <div
                                            className={`flex gap-3 ${
                                              !socialMediaLinks() ? '' : ''
                                            }`}
                                          >
                                            {socialMediaLinks()}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="flex items-center gap-2 mb-3 w-full mt-4">
                                    <div className="min-w-0 flex flex-col items-center md:items-start w-full">
                                      {/* <span className="text-gray-50 text-lg font-normal mb-2">
                                              {artistData?.artist_type === 'team'
                                                ? 'Team'
                                                : 'Artist'}{' '}
                                              Sub-Type
                                            </span> */}
                                      <div className="flex gap-2 flex-wrap mt-1 items-center justify-center md:justify-start">
                                        {artistData?.type?.map(
                                          (type, index) => (
                                            <span
                                              key={index}
                                              className="py-1 md:py-2 px-3 md:px-3 dark:bg-zinc-600 bg-whiteNew-0 rounded-full text-md md:text-[13px] dark:border-zinc-600 border-zinc-200 border-[1px] dark:text-whiteNew-0 bg-opacity-30 dark:bg-opacity-60 font-medium"
                                            >
                                              {type}
                                            </span>
                                          ),
                                        )}
                                        {!artistData?.type && (
                                          <>
                                            <span className="flex space-x-3 h-5">
                                              {[...Array(6)].map((_, idx) => (
                                                <Skeleton
                                                  avatar={false}
                                                  rows={true}
                                                  className={
                                                    'max-w-[70px] rounded-lg overflow-hidden h-full'
                                                  }
                                                />
                                              ))}
                                            </span>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <NormalSkeleton avatar={false} rows={8} />
                                </>
                              )}
                            </div>
                          </div>
                        </div>

                        {/* Old Page Views */}
                      </div>
                    </div>
                  </EventBanner>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-color-base-1 pt-[70px]">
            <div className="container-fluid lg:max-w-[1800px] xl:max-w-[1800px]">
              <div className="py-0 lg:py-10 px-md-4">
                <Row className="mt-1 lg:mt-5 px-1">
                  <div className="pt-0 md:pt-16 mb-5">
                    <TourBannerSwiper images={artistData?.artist_image} />
                  </div>
                </Row>

                <div className="mb-4 px-1">
                  <div className="flex flex-col rounded-xl px-0 border-[1px] border-level-4 shadow-md shadow-level-4 bg-color-base-3 dark:bg-color-base-2">
                    <div className="bg-color-base-2 dark:bg-color-base-3 px-3 py-3.5 rounded-t-xl">
                      <h2 className="text-xl font-normal dark:text-gray-50 text-zinc-800 py-1">
                        About{' '}
                        {artistData?.artist_type === 'team' ? 'Team' : 'Artist'}
                      </h2>
                    </div>
                    <div className="p-3 rounded-b-xl">
                      {artistData ? (
                        <>
                          <p className="dark:text-zinc-400 text-zinc-800 text-[15px]">
                            {artistData?.description?.length > 250
                              ? truncateDis
                                ? artistData?.description
                                : truncate(artistData?.description, 250)
                              : artistData?.description}
                            <span
                              className="dark:text-landingPurple-60 text-sky-600 cursor-pointer"
                              onClick={() => setTruncateDis(!truncateDis)}
                            >
                              {artistData?.description?.length > 250 ? (
                                truncateDis ? (
                                  <span className="inline-block ml-0.5">
                                    Read less
                                  </span>
                                ) : (
                                  <span className="inline-block ml-0.5">
                                    Read more
                                  </span>
                                )
                              ) : (
                                ''
                              )}
                            </span>
                          </p>
                        </>
                      ) : (
                        <>
                          <div className="w-full">
                            <NormalSkeleton avatar={false} rows={3} />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                {/* <Row>
                  <Col lg={12} xxl={12}>
                    <div className="">
                      <div className="border-b-[1px] pb-3 dark:border-zinc-800 border-zinc-200">
                        <HeadingTitle>
                          <h2 className="text-2xl font-normal dark:text-gray-50 text-zinc-800">
                            About{' '}
                            {artistData?.artist_type === 'team'
                              ? 'Team'
                              : 'Artist'}
                          </h2>
                        </HeadingTitle>
                      </div>
                      <p className="dark:text-white text-zinc-800 text-base p-4 bg-color-base-3 rounded-lg mt-4 mb-4">
                        {artistData?.description?.length > 250
                          ? truncateDis
                            ? artistData?.description
                            : truncate(artistData?.description, 250)
                          : artistData?.description}
                        <span
                          className="dark:text-landingPurple-60 text-sky-600 cursor-pointer"
                          onClick={() => setTruncateDis(!truncateDis)}
                        >
                          {artistData?.description?.length > 250 ? (
                            truncateDis ? (
                              <span className="inline-block ml-0.5">
                                Read less
                              </span>
                            ) : (
                              <span className="inline-block ml-0.5">
                                Read more
                              </span>
                            )
                          ) : (
                            ''
                          )}
                        </span>
                      </p>
                    </div>
                  </Col>
                </Row> */}
                {/* <Row>
                  <Col lg={12} xxl={12}>
                    <div className="">
                      <div className="border-b-[1px] pb-3 dark:border-zinc-800 border-zinc-200">
                        <div className="flex flex-row items-center justify-between">
                          <HeadingTitle>
                            <h2 className="text-2xl font-normal dark:text-gray-50 text-zinc-800">
                              List of Events
                            </h2>
                          </HeadingTitle>
                          <div className="flex flex-row bg-color-base-3 p-2 rounded-lg gap-2">
                            <Tooltip title={`List View`}>
                              <button className="text-lg dark:bg-zinc-800 bg-color-base-1 p-1.5 rounded-lg h-[32px] w-[32px] items-center justify-center shadow-sm">
                                <ListIconCustom />
                              </button>
                            </Tooltip>
                            <Tooltip title={`Card View`}>
                              <button className="text-lg p-1.5 rounded-lg dark:hover:bg-zinc-800 hover:bg-whiteNew-0 hover:shadow-sm h-[32px] w-[32px] justify-center items-center">
                                <CardIconCustom />
                              </button>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                      <div className="dark:bg-color-base-3 bg-color-base-3 rounded-lg mt-3 dark:border-zinc-800 border-zinc-200 border-1 p-[1px]">
                        {filterExpired(eventData)?.length > 0 && (
                          <div className="hidden md:flex md:flex-row px-3.5 py-2.5">
                            <div className="flex flex-col dark:text-zinc-100 text-zinc-800 text-sm font-normal w-[15%]">
                              Date & Time
                            </div>

                            <div className="flex flex-col dark:text-zinc-100 text-zinc-800 text-sm font-medium w-[40%]">
                              Event Name
                            </div>

                            <div className="flex flex-col dark:text-zinc-100 text-zinc-800 text-sm font-medium w-[20%]">
                              Venue
                            </div>

                            <div className="flex flex-col text-left md:text-right dark:text-zinc-100 text-zinc-800 text-sm font-medium w-[15%] px-2">
                              <span className="pe-20">Price Onwards</span>
                            </div>

                            <div className="flex flex-col dark:text-zinc-100 text-sm font-medium w-[10%]">
                              Action
                            </div>
                          </div>
                        )}

                        {filterExpired(eventData)?.map((event, index) => (
                          <div className="dark:bg-zinc-900 bg-whiteNew-0 border-b-[1px] dark:border-zinc-800 border-zinc-200 last:border-none">
                            <div className="flex flex-col md:flex-row md:items-center p-1 dark:bg-zinc-950 bg-[#fff] mt-[10px] md:mt-[0px] px-3 rounded-[7px]">
                              <div className="flex dark:text-zinc-400 text-zinc-700 flex-col w-[100%] md:w-[15%] my-2 md:my-[0px]">
                                <span className="text-[14px]">
                                  {moment(event?.start_date_time)?.format(
                                    'MM/DD/YY - HH:mm',
                                  )}
                                  
                                </span>
                                
                              </div>
                              <div className="flex dark:text-zinc-400 text-zinc-700 flex-col w-[100%] md:w-[40%] md:my-[0px]">
                                <span className="text-[14px]">
                                  {event?.name}
                                </span>
                                
                              </div>
                              <div className="flex dark:text-zinc-400 text-zinc-700 flex-row gap-2 w-[100%] md:w-[20%] md:my-[0px] items-center">
                                <span className="text-[14px]">
                                  {event?.event_type === 'Virtual'
                                    ? 'Virtual'
                                    : event?.venue[0]?.name || 'NA'}
                                </span>
                                <span className="h-[15px] bg-zinc-600 w-[1px] mx-2"></span>
                                <span className="dark:text-zinc-400 text-zinc-700 md:text-gray-500 text-[14px]">
                                  {event?.location}
                                </span>
                              </div>
                              <div className="flex dark:text-zinc-400 text-zinc-700 flex-col w-[100%] md:w-[15%] md:my-[0px] px-2">
                                <span className="text-lg hidden">
                                  Price Onwards
                                </span>
                                <span className="dark:text-zinc-400 text-zinc-700 pe-20 text-left md:text-right text-[14px]">
                                  <CurrencyFormat
                                    value={
                                      isNaN(
                                        Number(
                                          getLowestPrice(event?.category),
                                        )?.toFixed(2),
                                      )
                                        ? '-'
                                        : '$' +
                                          Number(
                                            getLowestPrice(event?.category),
                                          )?.toFixed(2)
                                    }
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    prefix={'$'}
                                  />
                                </span>
                              </div>

                              <div className="dark:text-zinc-400 text-zinc-700 flex flex-col w-[10%]">
                                <Link
                                  to={`/event-detail/${event?.slug}`}
                                  className={`p-2 ${primaryBtnClassGradient} lg:min-w-[110px] text-center text-gray-50duration-200 ease-in rounded-md w-24 max-w-[100px] !p-1`}
                                >
                                  Buy Now
                                </Link>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Col>
                  <Col>
                    {filterExpired(eventData)?.length === 0 && (
                      <div className="flex justify-center">
                        <NoDataFound
                          title={
                            'This artist/team is not associated with any event.'
                          }
                        />
                      </div>
                    )}
                  </Col>
                </Row> */}

                <div className="mb-4 border-[1px] border-level-4 shadow-md shadow-level-4 bg-color-base-3 dark:bg-color-base-2 rounded-xl mx-1">
                  <div className="flex flex-col rounded-lg px-0">
                    <div className="bg-color-base-2 dark:bg-color-base-3 px-3 pe-1 py-2.5 rounded-t-xl flex flex-row justify-between items-center">
                      <h2 className="text-xl font-normal dark:text-gray-50 text-zinc-800 py-2">
                        List of Events
                      </h2>

                      {isMobile ? (
                        <></>
                      ) : (
                        <div className="flex-row inline-flex bg-whiteNew-0 dark:bg-zinc-800 bg-opacity-90 dark:bg-opacity-90 rounded-lg gap-1.5 px-[2px] py-[2px] me-1.5">
                          <Tooltip title={`List View`}>
                            <button
                              className={`${
                                dataViews === 'list'
                                  ? 'dark:bg-zinc-900 bg-whiteNew-0 shadow-md'
                                  : ''
                              } text-lg p-1.5 rounded-lg h-[32px] w-[32px] items-center justify-center hover:bg-whiteNew-0 dark:hover:bg-zinc-900`}
                              onClick={() => setDataViews('list')}
                            >
                              <ListIconCustom />
                            </button>
                          </Tooltip>
                          <Tooltip title={`Card View`}>
                            <button
                              className={`${
                                dataViews === 'card'
                                  ? 'dark:bg-zinc-900 bg-whiteNew-0 shadow-md'
                                  : ''
                              } text-lg p-1.5 rounded-lg dark:hover:bg-zinc-900 hover:bg-whiteNew-0 h-[32px] w-[32px] justify-center items-center`}
                              onClick={() => setDataViews('card')}
                            >
                              <CardIconCustom />
                            </button>
                          </Tooltip>
                        </div>
                      )}
                    </div>
                  </div>

                  {isMobile ? (
                    <div className="p-2">
                      <TrendingSlider
                        data={filterExpired(eventData)}
                        from="artistEvents"
                      />
                    </div>
                  ) : (
                    <div>
                      {dataViews === 'card' &&
                      filterExpired(eventData)?.length > 0 ? (
                        <>
                          <TrendingSlider
                            data={filterExpired(eventData)}
                            from="artistEvents"
                          />
                        </>
                      ) : (
                        <>
                          <div className="dark:bg-color-base-2 bg-color-base-1 mt-0 border-level-4 border-t-[1px] p-[1px] rounded-b-xl overflow-hidden">
                            {filterExpired(eventData) ? (
                              <>
                                {filterExpired(eventData)?.length > 0 && (
                                  <div className="grid grid-cols-7 grid-flow-row-dense gap-4 px-3 py-2.5">
                                    <div className="flex flex-col dark:text-zinc-100 text-zinc-800 text-sm font-medium">
                                      Date & Time
                                    </div>

                                    <div className="flex flex-col dark:text-zinc-100 text-zinc-800 text-sm font-medium col-span-2">
                                      Event Name
                                    </div>

                                    <div className="flex flex-col dark:text-zinc-100 text-zinc-800 text-sm font-medium">
                                      Venue Name
                                    </div>

                                    <div className="flex flex-col dark:text-zinc-100 text-zinc-800 text-sm font-medium">
                                      Location
                                    </div>

                                    <div className="flex flex-col text-left md:text-right dark:text-zinc-100 text-zinc-800 text-sm font-medium px-2">
                                      <span className="pe-20">Price</span>
                                    </div>

                                    <div className="flex flex-col dark:text-zinc-100 text-sm font-medium">
                                      Action
                                    </div>
                                  </div>
                                )}

                                {filterExpired(eventData)?.length === 0 && (
                                  <div className="p-3">
                                    <NoDataFoundV1
                                      title={
                                        'This artist/team is not associated with any event.'
                                      }
                                      icon={<FaRegStickyNote />}
                                    />
                                  </div>
                                )}
                                {filterExpired(eventData)?.map(
                                  (event, index) => (
                                    <div className="bg-color-base-1 border-b-[1px] border-level-4 last:border-none">
                                      <div className="grid grid-cols-7 gap-4 px-3 py-2.5 rounded-[7px]">
                                        <div className="flex dark:text-zinc-400 text-zinc-700 flex-col">
                                          <span className="text-[14px]">
                                            {moment(
                                              event?.start_date_time,
                                            )?.format(DATEFORMAT)}
                                            {/* Jan 21, 2023 */}
                                          </span>
                                          {/* <span className="text-gray-500 text-base">
                             
                                  {moment(event?.start_date_time)?.format(
                                    'HH:mm',
                                  )}
                                </span> */}
                                        </div>
                                        <div className="flex dark:text-zinc-400 text-zinc-700 flex-col md:my-[0px] col-span-2">
                                          <span className="text-[14px]">
                                            {event?.name}
                                          </span>
                                          {/*
                                    <span className="text-gray-500 text-base">
                                      {truncate(event?.description, 50)}
                                    </span>
                              */}
                                        </div>
                                        <div className="flex dark:text-zinc-400 text-zinc-700 flex-col gap-0 md:my-[0px]">
                                          <span className="text-[14px]">
                                            {event?.event_type === 'Virtual'
                                              ? 'Virtual'
                                              : event?.venue[0]?.name || 'NA'}
                                          </span>
                                        </div>

                                        <div className="flex dark:text-zinc-400 text-zinc-700 flex-col gap-0 md:my-[0px]">
                                          <span className="dark:text-zinc-400 text-zinc-700 text-[14px]">
                                            {event?.location}
                                          </span>
                                        </div>

                                        <div className="flex dark:text-zinc-400 text-zinc-700 flex-col md:my-[0px] px-2">
                                          <span className="text-lg hidden">
                                            Price Onwards
                                          </span>
                                          <span className="dark:text-zinc-400 text-zinc-700 pe-20 text-left md:text-right text-[14px]">
                                            <CurrencyFormat
                                              value={
                                                isNaN(
                                                  Number(
                                                    getLowestPrice(
                                                      event?.category,
                                                    ),
                                                  )?.toFixed(2),
                                                )
                                                  ? '-'
                                                  : '$' +
                                                    Number(
                                                      getLowestPrice(
                                                        event?.category,
                                                      ),
                                                    )?.toFixed(2)
                                              }
                                              displayType={'text'}
                                              thousandSeparator={true}
                                              decimalScale={2}
                                              prefix={'$'}
                                            />
                                          </span>
                                        </div>

                                        <div className="dark:text-zinc-400 text-zinc-700 flex flex-col">
                                          <Link
                                            to={`/event-detail/${event?.slug}`}
                                            className={`p-2 ${primaryBtnClassGradientSec} lg:min-w-[110px] text-center text-gray-50duration-200 ease-in rounded-md w-24 max-w-[100px] !p-1`}
                                          >
                                            Buy Now
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  ),
                                )}
                              </>
                            ) : (
                              <>
                                <div className="w-full">
                                  <NormalSkeleton avatar={false} rows={5} />
                                </div>
                              </>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>

                {/* <div className="container-fluid  max-w-[2048px]">
                  <div className="py-7 px-md-0">
                    <Row>
                      <Col
                        lg={12}
                        xxl={12}
                        className="p-[0px] md:p-0 md:-scroll-ml-1.5"
                      >
                        <div className="">
                          <div className="border-b-[1px] pb-3 dark:border-zinc-800 border-zinc-200">
                            <HeadingTitle>
                              <h2 className="text-2xl font-normal dark:text-gray-50 text-zinc-800">
                                Trending Artists/Teams
                              </h2>
                            </HeadingTitle>
                          </div>
                          <div className="mt-3 mx-[-10px]">
                            <TopOrganiserSlider
                              data={topartist?.data?.data?.filter(
                                (artist) => artist?._id !== id,
                              )}
                            
                              type="artist"
                              refetch={topartist.mutate}
                            />
                          </div>
                          
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div> */}
                <div className="mb-4 border-[1px] border-level-4 shadow-md shadow-level-4 bg-color-base-3 dark:bg-color-base-2 rounded-xl mx-1">
                  <div className="flex flex-col rounded-lg px-0">
                    <div className="bg-color-base-2 dark:bg-color-base-3 px-3 py-3.5 rounded-t-xl flex justify-between">
                      <h2 className="text-xl font-normal dark:text-gray-50 text-zinc-800 py-1">
                        Trending Artists/Teams
                      </h2>
                      <div className="flex items-center gap-2 shrink-0">
                        <div>
                          <Link
                            to="/artists"
                            // className="hover:text-landingPurple-60 text-base font-semibold text-whiteNew-0 underline p-2.5 py-[7px] flex justify-between items-center gap-0.5 rounded-md bg-opacity-90 duration-200 ease-in"
                            className={
                              'p-2.5 px-[15px] dark:text-whiteNew-0 text-zinc-800 dark:bg-brown-800 bg-lightTheme-80 backdrop-blur-lg dark:hover:bg-brown-700 hover:bg-brown-600 hover:bg-opacity-90 rounded-lg flex gap-1 items-center justify-center lg:min-w-[100px] text-sm md:text-base leading-tight duration-200 ease-in'
                            }
                          >
                            <span>All Artists/Teams</span>
                            <MdKeyboardArrowRight />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="p-3 rounded-b-xl">
                    <TopOrganiserSlider
                      data={artist?.data}
                      // eventdetails={eventdetails}
                      type="artist"
                      refetch={topartist.mutate}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}
